import React, {  useContext} from 'react'
import "./post-body.css"
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../../../context/GlobalContex'
import LoadingSpinner from '../../../components/ui/loadingSpinner/LoadingSpinner'

const PostBody = () => {
  const {postData,} = useContext(GlobalContext)
  const {postId} = useParams()

  const post = postData.find(post => post?.url === postId)

  if(!post?.title){
    return <LoadingSpinner />
  }
  
  if (!post) {
    return <center>
      <h1>Post not found</h1>;
    </center>
  }

  return (
    <section className="post-body">
      <section className="post-data">
        {/* <h1 className="post-title">{post?.title}</h1> */}

        <article>
        {/* <p className="post-text">{post.intro}</p> */}
          {post?.content?.map(post => {
            return <>
              <p className="post-text">{post?.intro}</p>
              <h2 className="post-subheading">{post.subheading}</h2>
              {
              Array.isArray(post.content)
                ? 
                  <ul className="post-list">
                    {post.content.map(content => {
                      return <li className="post-list-item">
                        <h3 className="post-list-title">{content.title}</h3>
                        <p className="post-list-description">{content.description}</p>
                      </li>
                    })}
                  </ul>
                :
                  <p className="post-text">{post.content}</p> 
              }
            </>
          })}

          <p className="post-text">{post?.conclusion}</p>
        </article>
        
        <footer className="post-body-author-info">
          <div>
            <img src={post?.author.authorImg} className="post-body-author" alt={post?.author.name} />
            <h4 className="past-body-author-name">{post?.author.name}</h4>
          </div>

          <time datetime={post?.date} className="post-body">{post?.date}</time>
        </footer>

      </section>
    </section>
  )
}

export default PostBody
