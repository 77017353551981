import React from 'react'
import "./footer.css"
import BrandLogo from '../../components/ui/brandLogo/BrandLogo'
import { NavLink } from 'react-router-dom'
import email from "../../assets/svgs/email.svg"

const Footer = () => {
  return (
    <footer className="footer"  itemScope itemType="https://schema.org/WPFooter">
        <section className="footer">
            <BrandLogo />
            
            <nav className="footer" itemProp="hasPart" itemScope itemType="https://schema.org/SiteNavigationElement">

                <ul className="footer-list">
                    <h2 className="footer">Menu</h2>
                    <li className="footer-list-item">
                        <NavLink to="/" itemProp="url">
                            Home
                        </NavLink>
                    </li>

                    <li className="footer-list-item">
                        <NavLink to="posts" itemProp="url">
                            Posts
                        </NavLink>
                    </li>

                    <li className="footer-list-item">
                        <NavLink to="about" itemProp="url">
                            About
                        </NavLink>
                    </li>

                    <li className="footer-list-item">
                        <NavLink to="contact" itemProp="url">
                            Contact
                        </NavLink>
                    </li>
                </ul>

                <ul className="footer-list">
                    <h2 className="footer">Follow</h2>

                    <li className="footer-list-item">
                        <NavLink to="https://x.com/ventmoir" target="_blank" rel="noopener noreferrer" itemProp="sameAs">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
                            <path d="M19.6885 0H23.5219L15.1469 10.591L25 25H17.2854L11.2437 16.2599L4.32917 25H0.49375L9.45208 13.6715L0 0.0011525H7.91042L13.3719 7.9899L19.6885 0ZM18.3438 22.4623H20.4677L6.75625 2.40515H4.47708L18.3438 22.4623Z" fill="white"/>
                            </svg>
                        </NavLink>
                    </li>

                    <li className="footer-list-item">
                        <NavLink to="https://www.linkedin.com/company/vent-moir/" target="_blank" rel="noopener noreferrer" itemProp="sameAs">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512" fill="white">
                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                            </svg>
                        </NavLink>
                    </li>

                    <li className="footer-list-item">
                        <NavLink to="mailto:contactventmoir@gmail.com" target="_blank" rel="noopener noreferrer" itemProp="sameAs">
                            <img src={email} alt="Email" />
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </section>
        
        <center>
            <p>2024 Ventmoir. All rights reserved</p>
        </center>
    </footer>
  )
}

export default Footer
