import React, { useEffect } from 'react'
import AboutBrand from '../../features/about/aboutBrand/AboutBrand'
import AboutHero from '../../features/about/aboutHero/AboutHero'
import { Helmet } from 'react-helmet'
import { logo } from '../../CONST/logo'
import { blogUrl } from '../../CONST/blogUrl'

const About = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "mainEntity": {
      "@type": "Organization",
      "name": "Ventmoir",
      "url": `${blogUrl}`,
      "description": "Welcome to Ventmoir - A supportive and inclusive space for mental health journeys, where individuals can safely express their feelings, share experiences, and receive the support they need in their mental health journeys."
    },
    "publisher": {
      "@type": "Organization",
      "name": "Ventmoir",
      "logo": {
        "@type": "ImageObject",
        "url": logo
      }
    }
  };

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }, [])

  return (
    <main>
      <Helmet>
        <title>About | Ventmoir Blog</title>
        <meta name="author" content="Ventmoir" />
        <meta name="description" content="Welcome to Ventmoir - A supportive and inclusive space  space for mental health journeys, where individuals can safely express their feelings, share experiences, and receive the support they need in their mental health journeys." />
        <meta name="keywords" content="mental health, mental health support, wellness, mental health resources, mental health articles, mental health events, mental health social platform" />
        <meta name="last-modified" content="2024-06-18" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="About | Ventmoir" />
        <meta property="og:description" content="Welcome to Ventmoir - A supportive and inclusive space for mental health journeys, where individuals can safely express their feelings, share experiences, and receive the support they need in their mental health journeys." />
        <meta property="og:url" content={`${blogUrl}/about`} />
        <meta property="og:image" content={logo} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ventmoir Blog" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <AboutHero />
      <AboutBrand />
    </main>
  )
}

export default About
