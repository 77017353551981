import React, { createContext, useState, useEffect } from "react";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../services/firebase/firebase-config";
import postData from "../postData/postData";
export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  //   const [postData, setPostData] = useState([]);
  //   const [loading, setLoading] = useState(true);
  //   const [progress, setProgress] = useState(0);

  //   const blogPostRef = collection(db, "blogPost");

  //   const getBlogPost = async () => {
  //     try {
  //       const data = await getDocs(blogPostRef);

  //       // Simulate progress for demonstration purposes
  //       const totalDocs = data.docs.length;
  //       const queryData = data.docs.map((doc, index) => {
  //         setProgress(((index + 1) / totalDocs) * 100);
  //         return { ...doc.data(), id: doc.id };
  //       });

  //       setPostData(queryData);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   useEffect(() => {
  //     getBlogPost();
  //   }, []);
  return (
    <GlobalContext.Provider value={{ postData }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
