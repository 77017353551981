import React from 'react'
import "./not-found-page.css"
import Button from '../../components/ui/Button'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
    const navigate = useNavigate()
  return (
    <main className="not-found-page" role="main" itemScope itemType="http://schema.org/404Page">
          <Helmet>
          <title>404 - Page Not Found | Ventmoir Blog</title>
          <meta name="description" content="The page you are looking for does not exist." />
        </Helmet>
        <center>
            <h1 className="not-found-page" itemProp="headline">"404" - Page Not Found</h1>
            <p className="not-found-page" itemProp="description">We're sorry, but the page you are looking for doesn't exist.</p>
            <Button
                className="not-found-page"
                text={"Go back"}
                onClick={() => navigate(-1, {replace: true})}
            />
        </center>
    </main>
  )
}

export default NotFoundPage
