import React from 'react'
import "./join-us.css"
import { NavLink } from 'react-router-dom'

const JoinUs = () => {
  return (
    <aside className="join-us" itemScope itemType="http://schema.org/CreativeWork">
        <h2 className="join-us" itemProp="headline">Join Our Community</h2>
        <p className="join-us" itemProp="description">
            Be a part of our journey as we prepare for the website launch to stay updated. Together we can bridge the gap to between social media and mental health together.
        </p>
        <NavLink className="join-us" to="https://chat.whatsapp.com/BX7J6GVTxrhECuJOuqAxOT" itemProp="url" aria-label="Join our community">
            Join us
        </NavLink>
    </aside>
  )
}

export default JoinUs
