import React, { useContext } from 'react'
import "./post-hero.css"
import { GlobalContext } from '../../../context/GlobalContex'
import { useParams } from 'react-router-dom'

const PostHero = () => {
  const  {postData} = useContext(GlobalContext)
  const {postId} = useParams()
  const post = postData.find(post => post?.url === postId)

  const isInterview = post?.category === "Interview"

  return (
    <section className="post-page-hero">
      <h1 className="interview">
        {post.title}
      </h1>

      <section>
        <img src={post?.blogImg}className={ isInterview ? "is-interview" : "post-page-hero"}  alt={post.title} />

       { isInterview && <div>
          {/* <h1 className="interview">
          {post.title}
          </h1> */}
          <p className="interview">
           {post?.intro}
          </p>
        </div>}
      </section>
      
    </section>
  )
}

export default PostHero
