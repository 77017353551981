import React, { useContext } from 'react'
import "./blog-posts.css"
import PostCard from '../../../components/postCard/PostCard'
import { GlobalContext } from '../../../context/GlobalContex'

const BlogPosts = () => {
  const {postData} = useContext(GlobalContext)

  return (
    <section className="blog-posts" itemtype="https://schema.org/Blog">
      <h1 className="blog-post" itemprop="name">Latest Posts</h1>

      <div className="blog-post-container" itemprop="blogPost">
        {
          postData.sort((a,b) => b.id - (a.id)).map(post => {
            return <PostCard 
              key={post.id}
              title={post.title}
              intro={post.intro}
              author={post.author.name}
              authorImg={post.author.authorImg}
              date={post.date}
              postId={post.id}
              postImg={post.blogImg}
              postUrl={post.url}
            />
          })
        }
      </div>

    </section>
  )
}

export default BlogPosts
