import React, { useContext, useEffect, useState } from 'react'
import "./post-page.css"
import PostHero from '../../features/postPage/postHero/PostHero'
import PostBody from '../../features/postPage/postContent/PostBody'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { blogUrl } from '../../CONST/blogUrl'
import JoinUs from '../../components/joinUs/JoinUs'
import { GlobalContext } from '../../context/GlobalContex'
import LoadingSpinner from '../../components/ui/loadingSpinner/LoadingSpinner'

const PostPage = () => {
  const {postId} = useParams()
  const {postData} = useContext(GlobalContext)

  const post = postData.find(post => post?.url === postId)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if(!post?.title){
    return <LoadingSpinner />
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post?.title,
    "image": post?.blogImg,
    "articleBody": post?.intro,
    "author": {
        "@type": "Person",
        "name": post?.author.name,
        "image": post?.author.authorImg
    },
    "datePublished": post?.date,
    "url": `${blogUrl}/posts/${postId}`
};

  return (
    <main className="post-page">
      <Helmet>
        <link rel="canonical" href={`https://blog.ventmoir.com/posts/${postId}`} />
        <title>{post?.title} | Ventmoir Blog</title>
        <meta name="description" content={post?.intro} />
        <meta name="author" content={post?.author.name} />
        <meta name="last-modified" content="2024-06-18" />
        <meta name="date" content={post?.date} />
        <meta name="keywords" content="mental health, wellness, mental health resources, mental health articles, mental health events, mental health social platform" />
        <meta name="subject" content="Mental Health and Wellness" />
        <meta name="rating" content="General" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${post?.title} | Ventmoir Blog`} />
        <meta property="og:description" content={post?.intro} />
        <meta property="og:url" content={`${blogUrl}/posts/${postId}`} />
        <meta property="og:image" content={post?.blogImg} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Ventmoir Blog" />
        <meta property="og:author" content={post?.author.name} />
        <meta property="article:published_time" content={post?.date} />
        <meta property="linkedin:card" content="summary" />
        <meta property="linkedin:title" content={`${post?.title} | Ventmoir Blog`} />
        <meta property="linkedin:description" content={post?.intro} />
        <meta property="linkedin:url" content={`${blogUrl}/posts/${postId}`} />
        <meta property="linkedin:image" content={post?.blogImg} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <PostHero />
      <PostBody />
      <JoinUs />
    </main>
  )
}

export default PostPage
