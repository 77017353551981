import React, { useContext } from 'react'
import "./recent-post.css"
import PostCard from '../../../components/postCard/PostCard'
import { GlobalContext } from '../../../context/GlobalContex'

const RecentPost = () => {
  const {postData} = useContext(GlobalContext)

  return (
    <section className="recent-post" itemtype="https://schema.org/Blog">
        <h2 className="recent-post" itemprop="name">Recent Posts</h2>
        <section className="recent-post-content" itemprop="blogPost">
          {postData.sort((a,b) => b.id - a.id).map(post => {
            return <PostCard
            key={post.id}
            title={post.title}
            intro={post.intro}
            author={post.author.name}
            authorImg={post.author.authorImg}
            date={post.date}
            postId={post.id}
            postImg={post.blogImg}
            postUrl={post.url}
            />
          })}
        </section>
    </section>
  )
}

export default RecentPost
